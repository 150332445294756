.profile.header {
  min-height: 400px;
}

.cp-flows td, .cp-flows th {
  border: 1px solid #e9ecef;
  width: 50%;
}

.cp-flows th {
  font-weight: bold;
  background: #6d4443;
  color: white;
}

.cp-flows tbody tr:last-child > td > div {
  max-height: 100px;
  overflow: scroll;
}

.cp-flows tbody tr:last-child > td > div::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.cp-flows tbody tr:last-child > td > div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.cp-flows .form-check {
  padding-top: 0.2rem;
  font-size: 0.9rem;
}

.share-flow .modal-header {
  border-width: 1px;
}

.share-flow textarea {
  resize: none;
}

.share-flow code {
  line-height: 1.5;
  padding: .25rem .5rem;
  border-radius: .25rem;
  opacity: 1;
  background-color: #e9ecef;
  width: 100%;
  display: block;
  color: #656363;
}

.share-flow {
  border-left: 1px solid #e3e3e3;
}

.import-flow .im-note {
  line-height: 1.1;
}
