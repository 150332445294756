.flow-card {
  display: flex;
}

.flow-card .card {
  border: 2px solid #d0ceca;
  border-radius: 15px;
  overflow: hidden;
  width: 320px;
}

.flow-card .card-body {
  padding: 0;
}

.flow-card .controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flow-card .controls {
  padding: 0 6px;
}

.flow-card .controls i {
  margin: 10px 0;
  color: #9d9d9d;
}

.flow-btns > .btn {
  border-top: 1px solid #f3f2f1;
}
.flow-btns > p {
  margin: 0 auto;
}

input {
  border: 0;
  border-bottom: 1px solid #f3f0ec;
  padding: 3px 0px;
}

input:focus {
  border: 0;
  border-bottom: 1px solid #c5c5c5;
  padding: 3px 0px;
  outline: 0;
}

.flow-card .image-cont {
  position: relative;
}

.flow-card .image-cont img {
  max-height: 250px;
}

.flow-card .image-cont label {
  position: absolute;
  bottom: 0;
  padding: 0.5rem 1rem;
  background: #848a9075;
  margin: 0;
  cursor: pointer;
}

.image-cont.plholder {
  overflow: hidden;
}

.image-cont.plholder img {
  margin-bottom: -12px;
}

.card-btn {
  border: 0;
  display: block;
  box-shadow: 1px 0px 1px 2px #cccccc61;
  width: 100%;
  background-color: #fafafa;
  color: #1e90ff;
}

.add-btn {
  border: 0;
  background-color: #ffffff;
  outline: 0;
}
button:focus {
  outline: 0;
}

.remove-btn{
  margin-top: 3px;
  float: right;
  cursor: pointer;
}

.flow-name input {
  border: 1px solid #ccc;
  padding: 3px 10px;
  width: 95%;
}
.flow-name input:focus {
  border: 1px solid rgb(131, 141, 164);
}

*:focus {
  outline: 0;
}

.flow-view input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;  
  border: 0;
}

.flow-view .fa-trash, .flow-view .fa-upload, .flow-view .addBtn {
  display: none;
}

.sur_textarea {
  position: relative;
}

.sur_textarea > div, .sur_textarea > textarea {
  border: 0;
  padding: 0px 12px;
  min-height: 38px;
}

.sur_textarea > div {
  border: 0;
}

.sur_textarea > textarea {
  position: absolute;
  top: 0;
  background: none;
  color: transparent;
  caret-color: black;
  resize: none;
}

.sur_textarea > textarea::-webkit-input-placeholder {
  color: #757575;
}

.sur_textarea > textarea::-moz-placeholder {
  color: #757575;
}

.sur_textarea > textarea:-moz-placeholder {
  color: #757575;
}

.sur_textarea_div {
  white-space: pre-wrap;
}

.sur_textarea > textarea:focus {
  border: 0;
  outline: 0;
}

.sur_textarea > textarea:focus + .sur_textarea_div {
  background-color: #eff0f1;
}

.sur-tag {
  background-color: #b8d2f3;
  border-radius: 5px;
}
.sur-tag span:nth-child(odd) {
  color: transparent;
}
.tag-sample {
  text-align: center;
}
.tag-sample span {
  display: inline-block;
  margin: 0 5px;
  font-weight: bold;
}

.add-temp {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100px;
  border: 1px dotted;
  padding: 8px 0 6px;
  border-radius: 9px;
  cursor: pointer;
}

.add-temp span {
  font-size: 12px;
}

.add-temp i {
  color: #9d9d9d;
}

.nimg {
  min-width: 320px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ebecef;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.nimg.square {
  height: 180px;
}

.nimg.horizontal .asp-ratio span:first-child {
  background-color: #45434385;
  border-radius: 5px;
}

.nimg.square .asp-ratio span:last-child {
  background-color: #45434385;
  border-radius: 5px;
}

.nimg > .img-contr {
  position: absolute;
  top: 0;
  right: 0;
  align-items: flex-end;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
}

.flow-card .link-contr {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 7px 15px;
  cursor: pointer;
  color: white;
  z-index: 5;
}

.link-contr i {
  background: #645d5d85;
  border-radius: 50%;
  padding: 2px 4px;
  height: 25px;
  width: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.nimg > .img-contr i {
  border: 1px solid;
  border-radius: 50%;
  padding: 2px 4px;
  background: #645d5d85;
}

.nimg > div {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.nimg > .img-up label {
  color: #1e90ff;
  cursor: pointer;
}

.flow-card .controls .rem {
  cursor: pointer;
}

.img-contr.lft i {
  border: 0;
  border-radius: 0;
  background-color: unset;
}

.img-contr.lft i.fa-tv {
  overflow: hidden;
  height: 14px;
}

.img-contr.lft {
  display: flex;
  flex-direction: row;
  right: unset;
  left: 0;
}

.paging {
  position: relative;
}

.paging i {
  position: absolute;
  right: 26px;
  top: 24px;
  font-size: 1.5rem;
}

.paging ul {
  margin-right: 40px;
}

.page-item.active .page-link {
  background-color: #863131;
}