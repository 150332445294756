.loader-cont {
  position: fixed;
  background: #0000004a;
  z-index: 8000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-loader {
  top: 45%;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 90%;
}
