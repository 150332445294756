.page-list > a {
  padding: 0.5rem 1rem;
}

.page-select {
  box-shadow: 0px 0px 1px #ffffff;
}

.page-list {
  max-height: 350px;
  overflow-y: scroll;
}
