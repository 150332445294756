#connectPageModal {
  background-color: #060606e0;
  overflow: scroll;
}

.m-div {
  position: relative;
  display: flex;
  justify-content: center;
}

.m-name {
  color: white;
  font-size: 2rem;
  z-index: 9999;
  top: 40px;
  position: relative;
}

.con-pages {
  max-height: 400px;
  overflow: scroll;
}

.avatar {
  width: 40px;
  height: 40px;
}
