.bg-gradient-primary {
  background: linear-gradient(87deg, #ffffff, #a0a0a0 100%) !important;
}

.separator .fill-default {
  fill: #ffffff;
}

.user-acc {
  justify-content: center;
  display: block;
  background: white;
  margin-bottom: 5px;
  border-bottom: 1px solid #ededed;
  cursor: pointer;
}

.user-acc:hover {
  background-color: #f2f2f2;
}
