body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-area {
  position: relative;
  min-height: calc(100vh - 125px);
}

.bg-primary {
  background-color: #863131 !important;
}

.chart {
  height: unset;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
  background: #f3eaeb;
}

button.table-action {
  border: 0;
  background: unset;
}

.navbar-vertical.navbar-expand-xs {
  max-width: 250px;
}
@media (max-width: 1199.98px) {
  .sidenav {
      transform: translateX(-250px);
  }
}

.g-sidenav-hidden:not(.g-sidenav-show) .sidenav .nav-link-text {
  display: inline !important;
}

.sidenav .navbar-brand, .sidenav .navbar-heading {
  display: inline-block;
}

@media (min-width: 1200px) {
  .sidenav.fixed-left + .main-content {
    margin-left: 250px !important;
  }
}

.navbar-vertical .navbar-nav .nav-link > i {
  font-size: .9375rem;
  line-height: 1.5rem;
  min-width: unset;
  text-align: center;
  margin-right: 16px;
  margin-left: 3px;
}

.graph ul li.nav-item span.nav-link {
  cursor: pointer;
}

.graph ul li.nav-item span.nav-link.active {
  cursor: default;
}

.date-inp {
  padding-left: 7px;
  font-size: 13px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #6c6c6c;
}

.date-inp:focus {
  padding-left: 7px;
}

.modal-body .flow-card > div {
  margin: auto;
}

.mix-panel > div > div {
  padding: 0 10px;
  background: white;
  height: 300px;
  overflow-y: scroll;
  border: 1.2rem solid rgb(255, 255, 255);
}

.sel-flow-num {
  width: 20px;
  display: inline-block;
  color: #a7a7a7;
  font-size: 0.87rem;
  vertical-align: text-bottom;
  text-align: center;
}

.brd-btm {
  border-bottom: 1px solid #cccccc4a;
  display: flex;
}

.b-0 {
  border: 0 !important;
}

.bg-darkk {
  background-color: #0e1a30 !important;
}
